import * as React from "react";
import Layout from "../components/Layout";
import "./tc.sass";

const PricePage = () => {
  return (
    <Layout>
      <div id="tc-container">
        <h2>Jeu - Conditions Générales d'Utilisation</h2>
        <p>
          Article I : Organisation et contexte   Webpanda (ci-après dénommée la
          « Société Organisatrice » ou « l’organisatrice ») organise un jeu
          gratuit (ci-après dénommé le « Jeu »). La Société Organisatrice met ce
          Jeu en place pour le compte de restaurants et commerces partenaires
          dans le but de récompenser ses visiteurs (ci-après dénommés
          individuellement « le Joueur » et collectivement « les Joueurs ») se
          rendant chez un Etablissement partenaire de Weely (bar, restaurant,
          salle de concert, cinéma, beauté, bien-être etc.) participant au Jeu.
          Il est expressément entendu que le rôle de la Société Organisatrice se
          limite à organiser le Jeu et délivrer les dotations gagnées. Webpanda 
          n'ayant pas la qualité d’Etablissement, la Société ne saurait voir sa
          responsabilité engagée à ce titre. Le Jeu se déroule exclusivement sur
          le site Weely.   <br /> <br />
          Article II : Modalités de participation   La participation au Jeu est
          réservée aux Joueurs des établissements partenaires de Weely. La
          participation est conditionnée à la dépense du joueur au sein de
          l’etablissement partenaire. Weely ainsi que l’établissement partenaire
          pourrons, dans le cadre du jeu, demander un justificatif de paiement
          (addition, facture, ticket de caisse etc.) aux joueurs. Tout Joueur
          qui souhaite participer au Jeu doit : • Sélectionner l’Etablissement
          où il souhaite jouer ; • Indiquer son email ; • Faire une des actions
          demandées (suivre l’établissement sur Instagram, Facebook, laisser un
          commentaire sur Google ou répondre à une question sur la satisfaction
          de la visite; • Faire tourner la roue virtuelle mise à disposition sur
          le site ou l’application. Suite à la participation au jeu, le Joueur
          s’abonne aux actualités et offres exclusives de l’Etablissement par
          l’intermédiaire de Weely. S’il le souhaite, il peut s’y opposer lors
          de l’inscription au jeu ou a posteriori.   <br /> <br />
          Article III : Dotation   Selon les Etablissements, la dotation du Jeu
          peut consister en un cadeau ou prix offert par l’Etablissement. Ce
          prix sera disponible lors de la prochaine visite du joueur au sein de
          l’établissement. Se cadeau• S’il s’agit d’un prix offert par
          l’établissement, la nature de la dotation ainsi que le stock
          disponible sont définis spécifiquement par l’établissement. Il peut
          s’agir par exemple d’une boisson offerte dans le cadre d’un prochaine
          visite, ou d’un statégie 1 acheté/1 offert, par exemple.   
          <br /> <br />
          Article IV : Désignation des gagnants   Les tirages au sort sont
          effectués automatiquement consécutivement à la participation grâce à
          un algorithme aléatoire. Une fois la roue virtuelle tournée, le Joueur
          découvre son gain.. Les gagnants recevront un e-mail de confirmation à
          montrer au personnel de l'établissement pour réclamer leur gain lors
          de leur prochaine visite. • S'il s'avère qu'un participant a gagné en
          ne respectant pas le présent règlement, par des moyens frauduleux, ou
          par des moyens autres que ceux résultant du processus décrit par Weely
          ou en violant l’une des conditions de participation, il perdrait alors
          sa qualité de gagnant, sans préjudice d'éventuelles poursuites
          susceptibles d'être intentées à l'encontre du participant par la
          Société Organisatrice. Dans ce cas, le participant recevra un email
          pour l’informer de la perte de sa qualité de gagnant incluant un
          rappel du présent règlement.   <br /> <br />
          Article V : Conditions de participation   L’inscription au Jeu
          implique l'acceptation sans réserve et le respect des dispositions du
          présent règlement, accessible à tout moment durant le Jeu à partir du
          site Weely et au sujet duquel la Société Organisatrice statue en
          dernier ressort sur tout cas litigieux et toute difficulté
          d’interprétation. En cas de refus de tout ou partie du présent
          règlement, il appartient aux Joueurs de s'abstenir de participer au
          Jeu.• La participation est strictement nominative et le joueur ne peut
          en aucun cas jouer sous plusieurs pseudonymes ou pour le compte
          d’autres membres. • Il est entendu qu’un participant est défini comme
          une personne physique unique : toute utilisation de comptes membres
          différents par un même participant sera considérée comme une tentative
          de fraude entraînant l’élimination définitive du participant. • Il est
          rigoureusement interdit pour un participant de jouer à partir d'un
          email ouvert au bénéfice d'une autre personne. Un seul et unique email
          devra être utilisé par personne physique participante. • Il est
          rigoureusement interdit, par quelque procédé que ce soit, de modifier
          ou de tenter de modifier les dispositifs de Jeu proposés, notamment
          afin d'en modifier les résultats ou d'influencer par un moyen
          automatisé ou déloyal la validité du tirage au sort. • Afin de
          prévenir le risque de fraude, chaque participant ne peut jouer qu’une
          seule fois par Etablissement dans une même journée. • Chaque
          participation est liée à une addition ou facture unique. Par
          conséquent : • Un même participant ne peut prendre part au tirage au
          sort qu’une seule fois par addition ou facture.La Société
          Organisatrice s’autorise à faire toutes les vérifications nécessaires
          afin de vérifier l’absence de fraude.   <br /> <br />
          Article VI : Remise des dotations   Les modalités de règlement de la
          dotation seront précisées dans un e-mail dès qu’un participant
          acquiert le statut de gagnant. • Dans le cas des dotations fournies
          par l’Etablissement, il en revient au personnel de l’Etablissement de
          contrôler le respect des conditions de participation et de scanner le
          cadeau reçu par e-mail. Dans le cas où tout ou partie des coordonnées
          du gagnant s’avéreraient manifestement fausses ou erronées, notamment
          son adresse électronique ou son numéro de téléphone, il
          n’appartiendrait en aucun cas à la Société Organisatrice d’effectuer
          des recherches de quelque nature que ce soit pour retrouver le
          gagnant. Celui-ci perdrait le bénéfice de son gain et ne pourrait
          prétendre à aucune compensation.  <br /> <br /> Article VII : Loi
          applicable et juridiction   La participation à ce Jeu implique
          l'acceptation sans réserve du présent règlement en toutes ses
          stipulations. En cas de contestation, seul sera recevable un courriel
          à contact@weely.ch dans un délai de 30 (trente) jours maximum après la
          date de participation. Sauf en cas d'erreurs manifestes, il est
          convenu que les informations résultant des systèmes informatiques de
          la Société Organisatrice ont force probante dans tout litige quant aux
          conditions de connexion, aux soumissions de contributions et au
          traitement informatique des dites informations relatives au Jeu. Les
          participants sont soumis à la réglementation Suisse applicable aux
          jeux et concours. Tout litige qui ne pourra être réglé à l'amiable
          sera soumis aux tribunaux compétents dont dépend le siège social de la
          Société Organisatrice, sauf dispositions d'ordre public contraires.
          Dans l'hypothèse où l'une des clauses du présent règlement serait
          déclarée nulle et non avenue, cela ne saurait en aucun cas affecter la
          validité du règlement lui-même et toutes ses autres clauses
          conserveraient leur force et leur portée.   Pour toute question
          relative au fonctionnement du jeu : contact@weely.ch 
        </p>
      </div>
    </Layout>
  );
};

export default PricePage;
